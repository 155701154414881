<template>
    <button
        :is="renderAs"
        v-bind="$attrs"
        class="kk-button"
        :type="buttonType"
        :class="[type, {
            anchor: renderAs === 'a' || renderAs === 'router-link',
            big,
            desktop,
            flat,
            full,
            icon,
            loading,
            outlined,
        }]"
        v-on="inputListeners"
    >
        <span :class="{ 'flex-fix': flexFix }">
            <slot />
            <svg-icon
                v-if="loading"
                name="loading"
                height="20"
                width="20"
            />
        </span>
    </button>
</template>

<script>
import svgIcon from '../svg-icon/svg-icon.vue';

export default {
    name: 'kk-button',
    components: {
        svgIcon,
    },
    props: {
        big: {
            type: Boolean,
            default: false,
        },
        desktop: {
            type: Boolean,
            default: false,
        },
        flat: {
            default: false,
            type: Boolean,
        },
        full: {
            default: false,
            type: Boolean,
        },
        icon: {
            default: false,
            type: Boolean,
        },
        loading: {
            default: false,
            type: Boolean,
        },
        outlined: {
            default: false,
            type: Boolean,
        },
        type: {
            default: 'primary',
            type: String,
            validator: (value) => {
                return [
                    'basic',
                    'clean',
                    'danger',
                    'default',
                    'link',
                    'primary',
                    'success',
                    'warning',
                    'transparent',
                    'transparent-primary',
                    'flat-icon',
                ].includes(value);
            },
        },
        buttonType: {
            default: 'submit',
            type: String,
            validator: (value) => {
                return [
                    'submit',
                    'button',
                    'reset',
                ].includes(value);
            },
        },
        flexFix: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        /**
         * Used to pass all event listeners to spesific element so
         * event listeners from outside component does not need .native
         * https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
         * @returns all active event-listeners
         */
        inputListeners: function() {
            return this.$listeners;
        },

        renderAs() {
            if (this.$attrs.to) {
                return 'router-link';
            }

            if (this.$attrs.href) {
                return 'a';
            }

            return 'button';
        },
    },
};
</script>

<style lang="scss" scoped>
@use "sass:color";
@import '../../sass/variables';

@mixin buttonType($color, $textColor, $outlinedColor) {
    background-color: $color;
    color: $textColor;

    &:hover {
        background-color: color.scale($color, $lightness: -15%);

        &:disabled {
            background-color: $color;
        }
    }

    &.outlined {
        background-color: transparent;
        border-color: $color;

        @if $color == $yellow {
            color: var(--color-dark-text);
        } @else {
            color: $outlinedColor; // Keep same color as border
        }

        &:hover, &.active {
            background-color: $color;
            border-color: $color;
            box-shadow: 0 0 1px $color;
            color: $textColor;
            text-decoration: none;

            &:disabled {
                border-color: $color !important;
                box-shadow: none;
                color: $textColor;
                text-decoration: none;
            }
        }
    }
}

.kk-button {
    border-radius: 25px;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: normal;
    line-height: unset;
    margin: 0;
    min-height: 30px;
    min-width: 100px;
    outline: none;
    padding: 5px 2em;
    text-align: center;
    transition: all 150ms ease-in-out;
    width: unset;

    .flex-fix {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    &.flat {
        border-radius: 4px;
        min-height: 10px;
        padding: 10px;
        width: 100%;
    }
    &.full {
        width: 100% !important;
    }
    &.big {
        font-size: 1.3em;
        height: 50px;
    }
    &.loading {
        position: relative;

        .svg-icon {
            position: absolute;
            right: calc(2em - 23px);
        }
        &.outlined {
            .svg-icon {
                padding-left: 30px;
            }
            .svg-icon ::v-deep .path {
                stroke: #000;
            }
        }
    }
    &.outlined {
        border-radius: 6px;
        border-style: solid;
        border-width: 1px;
        font-weight: 400;
        min-height: 10px;
        min-width: 80px;
        outline: 0;
        padding: 4px 20px;
        transition: all .2s ease-in-out;
    }
    &.desktop {
        border-radius: 7px;
        min-width: unset;
        padding: 7px 10px;
        width: auto;
    }
    &.icon {
        .svg-icon {
            float: right;
        }
    }
    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }
    // Button color types
    &.transparent {
        @include buttonType(transparent, $darkText, #5e5e5e);
        &:hover {
            color: color.scale($darkText, $lightness: 25%);
        }
    }
    &.transparent-primary {
        @include buttonType(transparent, $blue, $blue);
        &:hover {
            color: color.scale($blue, $lightness: -15%);
        }
    }
    &.clean {
        @include buttonType(#aaa, #fff, #5e5e5e)
    }
    &.basic {
        @include buttonType(#ddd, $darkText, #ddd)
    }
    &.danger {
        @include buttonType($red, #fff, $red)
    }
    &.default {
        @include buttonType(#fff, $darkText, #fff)
    }
    &.primary {
        @include buttonType($blue, #fff, $blue)
    }
    &.success {
        @include buttonType($green, #fff, $green)
    }
    &.warning {
        @include buttonType($yellow, $darkText, $yellow)
    }
    &.flat-icon {
        min-width: unset;
        border-radius: 4px;
        border: 1px solid #979797;
        color: var(--text-primary);
        background-color: unset;
        padding: 8px;
        cursor: pointer;
        font-size: 14px;

        .flex-fix {
            white-space: nowrap;
        }

        &:hover {
            background: var(--background-level-1);
        }
    }
    &.link {
        background: none;
        color: #757575;
        font-size: 1em;
        font-weight: 500;
        padding: 0 40px;
        text-decoration: underline;
        text-transform: uppercase;
        width: unset;

        &:hover {
            color: var(--link-primary);
        }
    }
    &.anchor {
        display: inline-flex;
        justify-content: center;
    }
    // Remove ugly default dotted outline on focus in Firefox
    &::-moz-focus-inner {
        border: 0;
    }
}
</style>
