<template>
    <vue-select
        ref="select"
        class="mobile-vue-select"
        :style="cssVars"
        v-bind="attrs"
        v-on="$listeners"
    >
        <!-- Grouping mode -->
        <template v-if="groupKey !== null" #option="option">
            <div v-if="option[groupKey]" class="group">
                <b>{{ option[groupKey] }}</b>
            </div>
            <span v-if="! option[groupKey]">
                {{ option[label] }}
            </span>
        </template>
        <template #no-options>
            <!-- Need to hide the "no options" when we can add -->
            <span v-if="addable" />
            <span v-else>{{ trans('shared.Ingen resultat') }}</span>
        </template>
        <template v-if="addable" #list-footer="{ search }">
            <li
                v-if="search && ! options.find(f => f && f[label] === search)"
                class="vs__dropdown-option select-footer"
                role="option"
                @click.prevent="onOptionAdded(search)"
            >
                <fa-icon :icon="['fal', 'plus-circle']" style="margin-right: 5px" />
                {{ addableText || trans('shared.Legg til') }} «{{ search }}»
            </li>
        </template>
        <template #open-indicator="{ attributes }">
            <fa-icon v-bind="attributes" :icon="['fal', 'chevron-down']" />
        </template>
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </vue-select>
</template>

<script>
import vueSelect from 'vue-select';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faPlusCircle, faChevronDown);

export default {
    name: 'mobile-vue-select',
    components: { vueSelect },
    props: {
        addable: {
            type: Boolean,
            default: false,
        },
        /**
         * Text that is prefixed in front of the search string when given the option to add a new item
         */
        addableText: {
            type: String,
            default: null,
        },
        /**
         * Which key in the object should we group by. When set, grouping is automatically enabled.
         */
        groupKey: {
            type: String,
            default: null,
        },
        itemHeight: {
            type: Number,
            default: 45,
        },
        label: {
            type: String,
            default: 'label',
        },
        selectHeight: {
            type: Number,
            default: 45,
        },
    },
    computed: {
        attrs() {
            return {
                ...this.$attrs,
                label: this.label,
                options: this.options,
                // If groupKey is set we want to disable the ability to click the group headers
                selectable: this.groupKey
                    ? option => option[this.groupKey] === null
                    : this.$attrs?.selectable,
            };
        },
        cssVars() {
            return {
                '--item-height': this.itemHeight + 'px',
                '--select-height': this.selectHeight + 'px',
            };
        },
        options() {
            if (!this.groupKey) {
                return this.$attrs.options || [];
            }

            if (!this.$attrs.options) {
                return [];
            }

            // We need to stringify and parse so we don't manipulate the origin
            const options = JSON.parse(JSON.stringify(this.$attrs.options)) || [];
            const label = this.label;
            let remapped = [];

            // Sort the options by groups so they come after each other
            const sortedOptions = options.sort((a, b) => {
                if (a === b) {
                    return 0;
                }

                return a[this.groupKey]?.localeCompare(b[this.groupKey]);
            });

            const groupLabels = (group) => {
                return sortedOptions.filter(option => option[this.groupKey] === group)
                    .map(option => option[this.label]);
            };

            sortedOptions.forEach((option) => {
                if (option[this.groupKey] && !remapped.find(r => r[this.groupKey] === option[this.groupKey])) {
                    remapped.push({
                        [this.groupKey]: option[this.groupKey],
                        // Trick to show group when searching
                        [label]: groupLabels(option[this.groupKey]).join(' '),
                    });
                }

                option[this.groupKey] = null;
                remapped.push(option);
            });

            return remapped;
        },
    },

    methods: {
        blurSelect() {
            if ('activeElement' in document) {
                document.activeElement.blur();
            }
        },

        onOptionAdded(search) {
            this.$emit('option-added', search);
            this.blurSelect();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~vue-select/dist/vue-select.css';
@import '~sass/variables';

$vs-component-line-height: 2;
$vs-dropdown-max-height: 400px;

.mobile-vue-select {
    --vs-font-size: 1em;
    background-color: #fff;
    margin-bottom: 20px;
    width: 100%;

    ::v-deep {
        .vs__dropdown-toggle {
            min-height: var(--select-height);

            &[aria-expanded=false] {
                .vs__selected-options {
                    > span + input {
                        height: 0;
                        width: 0;
                        padding: 0;
                        margin: 0;
                        z-index: -1;
                    }
                }
            }
        }

        .vs__search::placeholder {
            color: #696969;
            font-size: var(--vs-font-size);
        }

        .vs__selected {
            display: block;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .vs__selected-options {
            overflow: hidden;
        }

        .vs__dropdown-menu li {
            word-break: break-word;
        }

        .vs__dropdown-option {
            display: flex;
            align-items: center;
            min-height: var(--item-height);
            white-space: normal;

            &--disabled {
                background: $bodyBackground;
                color: var(--color-dark-text);
            }
        }
    }

    .select-footer {
        &:hover {
            background-color: var(--primary);
            color: var(--text-inverse);
        }
    }
}
</style>
