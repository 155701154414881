<template>
    <div class="new-contact">
        <div v-if="!contactPersonMode" class="new-contact-form">
            <h2 :class="[mobileView ? 'ks-text-h4' : 'ks-text-body-lg-medium', 'text-primary']">
                {{ useCustomerLabel ? trans('contacts.Legg til kunde') : trans('contacts.Legg til kontakt') }}
            </h2>

            <required-field show-required-text class="required-text" />

            <label>
                <span> {{ trans('shared.Type') }} <required-field /></span>
                <div class="checkbox-wrapper">
                    <kk-checkbox
                        size="small"
                        :value="newContactForm.contact_type === 'COMPANY'"
                        @input="newContactForm.contact_type = 'COMPANY'"
                    >
                        {{ trans('contacts.Bedrift') }}
                    </kk-checkbox>
                    <kk-checkbox
                        size="small"
                        :value="newContactForm.contact_type === 'PERSON'"
                        @input="newContactForm.contact_type = 'PERSON'"
                    >
                        {{ trans('contacts.Person') }}
                    </kk-checkbox>
                </div>
            </label>

            <label>
                <span> {{ trans('shared.Navn') }} <required-field /></span>
                <div class="input-wrapper">
                    <input
                        v-model="newContactForm.name"
                        class="form-input"
                        type="text"
                    >
                </div>
            </label>

            <label>
                {{ trans('shared.Organisasjonsnummer') }}
                <div class="input-wrapper">
                    <input
                        v-model="newContactForm.vat_identification_number"
                        :disabled="newContactForm.contact_type !== 'COMPANY'"
                        class="form-input"
                        type="text"
                    >
                </div>
            </label>

            <label @click.prevent>
                {{ trans('shared.Kategorier') }}
                <v-select
                    v-model="newContactForm.categories"
                    class="form-select"
                    clearable
                    label="name"
                    :options="categories"
                    :create-option="name => name"
                />
            </label>

            <label>
                {{ trans('shared.Telefon') }}
                <div class="input-wrapper">
                    <input
                        v-model="newContactForm.phone"
                        class="form-input"
                        type="number"
                        @input="validationErrors.phone = false"
                    >
                </div>
            </label>
            <p v-if="validationErrors.phone" class="error">
                {{ trans('shared.Må være et gyldig telefonnummer') }}
            </p>

            <label>
                <span>{{ trans('shared.Mobil') }} <required-field v-if="isMobileRequired" /> </span>
                <div class="input-wrapper">
                    <input
                        v-model="newContactForm.mobile"
                        class="form-input"
                        type="number"
                        @input="validationErrors.mobile = false"
                    >
                </div>
            </label>
            <p v-if="validationErrors.mobile" class="error">
                {{ trans('shared.Må være et gyldig telefonnummer') }}
            </p>

            <label>
                <span>{{ trans('shared.E-post') }} <required-field v-if="isEmailRequired" /></span>
                <div class="input-wrapper">
                    <input
                        v-model="newContactForm.email"
                        class="form-input"
                        type="email"
                        @input="validationErrors.email = false"
                    >
                </div>
            </label>
            <p v-if="validationErrors.email" class="error">
                {{ trans('shared.Må være en gyldig e-post') }}
            </p>

            <label>
                {{ trans('contacts.Adresse linje 1') }}
                <div :class="showAddressLine2 ? 'input-wrapper' : 'input-button-wrapper'">
                    <input
                        v-model="newContactForm.address_line_1"
                        class="form-input"
                        type="text"
                    >
                    <kk-icon-button
                        v-if="!showAddressLine2"
                        :tooltip-text="mobileView ? null : trans('contacts.Legg til adresse linje 2')"
                        :icon="['fal', 'circle-plus']"
                        size="small"
                        @click="showAddressLine2 = !showAddressLine2"
                    />
                </div>
            </label>

            <label
                v-show="newContactForm.address_line_2 || showAddressLine2"
            >
                {{ trans('contacts.Adresse linje 2') }}
                <div class="input-wrapper">
                    <input
                        v-model="newContactForm.address_line_2"
                        class="form-input"
                        type="text"
                    >
                </div>
            </label>

            <label>
                <span>
                    {{ trans('contacts.Postnr') }}
                    <required-field v-if="isPostalCodeRequired" />
                </span>
                <div class="input-wrapper">
                    <input
                        v-model="newContactForm.postal_code"
                        class="form-input"
                        type="text"
                        @input="validationErrors.postal_code = false"
                    >
                </div>
            </label>
            <p v-if="validationErrors.postal_code && isPostalCodeRequired" class="error">
                {{ trans('shared.Postnummer må fylles ut') }}
            </p>

            <label>
                <span>
                    {{ trans('contacts.Poststed') }}
                    <required-field v-if="isCityRequired" />
                </span>
                <div class="input-wrapper">
                    <input
                        v-model="newContactForm.city"
                        class="form-input"
                        type="text"
                        @input="validationErrors.city = false"
                    >
                </div>
            </label>
            <p v-if="validationErrors.city && isCityRequired" class="error">
                {{ trans('shared.Poststed må fylles ut') }}
            </p>

            <div>
                <kk-button
                    type="transparent-primary"
                    button-type="button"
                    :class="{'mobile' : mobileView}"
                    :disabled="isAddButtonDisabled"
                    @click="showContactPersonMode"
                >
                    <fa-icon :icon="['fal', 'plus']" />
                    {{ trans('contacts.Legg til kontaktperson') }}
                </kk-button>
            </div>
        </div>

        <div v-if="contactPersonMode" class="new-contact-person-form">
            <h2 :class="[mobileView ? 'ks-text-h4' : 'ks-text-body-lg-medium', 'text-primary']">
                {{ trans('contacts.Legg til kontaktperson') }}
            </h2>

            <required-field show-required-text class="required-text" />

            <label>
                <span>
                    {{ useCustomerLabel ? trans('orders.Kunde') : trans('shared.Kontakt') }}
                    <required-field />
                </span>
                <input
                    v-if="!newContactForm.id"
                    v-model="newContactForm.name"
                    class="form-input"
                    type="text"
                    disabled
                >
                <v-select
                    v-else
                    v-model="newContactForm"
                    class="form-select"
                    label="name"
                    :options="contactOptions"
                    :clearable="false"
                >
                    <template #option="{ name }">
                        <div class="item-ellipsis" :title="name">
                            {{ name }}
                        </div>
                    </template>
                </v-select>
            </label>
            <label>
                <span>{{ trans('shared.Fornavn') }} <required-field /></span>
                <input v-model="newContactPersonForm.first_name" class="form-input" type="text">
            </label>
            <label>
                <span>{{ trans('shared.Etternavn') }} <required-field /></span>
                <input v-model="newContactPersonForm.last_name" class="form-input" type="text">
            </label>
            <label>
                {{ trans('shared.Telefon') }}
                <div class="input-wrapper">
                    <input
                        v-model="newContactPersonForm.phone"
                        class="form-input"
                        type="number"
                        @input="validationErrors.personPhone = false"
                    >
                    <p v-if="validationErrors.personPhone" class="error">
                        {{ trans('shared.Må være et gyldig telefonnummer') }}
                    </p>
                </div>
            </label>
            <label>
                <span>{{ trans('shared.Mobil') }} <required-field v-if="isMobileRequired" /></span>
                <div class="input-wrapper">
                    <input
                        v-model="newContactPersonForm.mobile"
                        class="form-input"
                        type="number"
                        @input="validationErrors.personMobile = false"
                    >
                    <p v-if="validationErrors.personMobile" class="error">
                        {{ trans('shared.Må være et gyldig telefonnummer') }}
                    </p>
                </div>
            </label>
            <label>
                <span>{{ trans('shared.E-post') }} <required-field v-if="isEmailRequired" /></span>
                <div class="input-wrapper">
                    <input
                        v-model="newContactPersonForm.email"
                        class="form-input"
                        type="email"
                        @input="validationErrors.personEmail = false"
                    >
                    <p v-if="validationErrors.personEmail" class="error">
                        {{ trans('shared.Må være en gyldig e-post') }}
                    </p>
                </div>
            </label>
            <label>
                {{ trans('shared.Stilling') }}
                <input v-model="newContactPersonForm.position" class="form-input" type="text">
            </label>
        </div>
    </div>
</template>

<script>
import { trans } from '../../../mixin/trans';
import requiredField from '@/components/required-field/required-field.vue';

import kkCheckbox from '../../kk-checkbox/kk-checkbox.vue';
import kkButton from '../../kk-button/kk-button.vue';
import kkIconButton from '../../kk-icon-button/kk-icon-button.vue';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import ks from '../../../lib/ks';

const defaultValidationErrors = () => ({
    phone: false,
    mobile: false,
    email: false,
    postal_code: false,
    city: false,
    personPhone: false,
    personMobile: false,
    personEmail: false,
});

const emptyContact = () => ({
    name: '',
    contact_type: 'COMPANY',
    vat_identification_number: '',
    phone: '',
    mobile: '',
    email: '',
    categories: [],
    address_line_1: '',
    address_line_2: '',
    postal_code: '',
    city: '',
    persons: [],
    is_vendor: false,
    is_customer: true,
});

const emptyPerson = () => ({
    first_name: '',
    last_name: '',
    phone: '',
    mobile: '',
    email: '',
    position: '',
});

export default {
    name: 'new-contact',
    components: {
        kkCheckbox,
        kkButton,
        vSelect,
        requiredField,
        kkIconButton,
    },
    mixins: [trans],
    props: {
        contactOptions: {
            type: Array,
            default: () => [],
        },
        categories: {
            type: Array,
            default: () => [],
        },
        disableButton: {
            type: Boolean,
            default: false,
        },
        /**
         * If true, modal titles and labels inside use 'Customer' instead of 'Contact' labels / texts
         */
        useCustomerLabel: {
            type: Boolean,
            default: false,
        },
        contactPersonMode: {
            type: Boolean,
            default: false,
        },
        newModeContact: {
            type: Object,
            default: () => null,
        },
        primary: {
            type: String,
            default: 'email',
            validator: primary => ['email', 'mobile', 'none', 'either'].includes(primary),
        },
        mobileView: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            newContactForm: null,
            newContactPersonForm: null,
            validationErrors: defaultValidationErrors(),
            showAddressLine2: false,
        };
    },
    computed: {
        disableSaveContactButton() {
            return this.newContactForm.name.length === 0;
        },
        shouldSendPerson() {
            return this.newContactPersonForm.first_name.length > 0
                && this.newContactPersonForm.last_name.length > 0;
        },
        isAddButtonDisabled() {
            if (this.contactPersonMode) {
                return !this.newContactPersonForm?.first_name
                    || !this.newContactPersonForm?.last_name
                    || (this.isMobileRequired && !this.newContactPersonForm?.mobile)
                    || (this.isEmailRequired && !this.newContactPersonForm?.email);
            } else {
                return !this.newContactForm?.name
                    || (this.isCityRequired && !this.newContactForm?.city)
                    || (this.isPostalCodeRequired && !this.newContactForm?.postal_code)
                    || (this.isMobileRequired && !this.newContactForm?.mobile)
                    || (this.isEmailRequired && !this.newContactForm?.email);
            }
        },
        isPostalCodeRequired() {
            return !!this.newContactForm?.address_line_1
                || !!this.newContactForm?.address_line_2
                || !!this.newContactForm?.city;
        },
        isCityRequired() {
            return !!this.newContactForm?.address_line_1
                || !!this.newContactForm?.address_line_2
                || !!this.newContactForm?.postal_code;
        },
        isMobileRequired() {
            let form = this.contactPersonMode ? this.newContactPersonForm : this.newContactForm;

            return this.primary === 'mobile'
                || (this.primary === 'either' && !form.mobile && !form.email)
                || (this.primary === 'either' && form.mobile && !form.email);
        },
        isEmailRequired() {
            let form = this.contactPersonMode ? this.newContactPersonForm : this.newContactForm;

            return this.primary === 'email'
                || (this.primary === 'either' && !form.mobile && !form.email)
                || (this.primary === 'either' && !form.mobile && form.email);
        },
    },
    watch: {
        isAddButtonDisabled: {
            handler() {
                this.$emit('disable-add-button', this.isAddButtonDisabled);
            },
            immediate: true,
        },
    },
    created() {
        if (this.newModeContact) {
            this.newContactForm = this.newModeContact;
            this.newContactPersonForm = emptyPerson();
        } else {
            this.emptyForm();
        }
    },
    methods: {
        emptyForm() {
            this.newContactForm = emptyContact();
            this.newContactPersonForm = emptyPerson();
        },
        checkForDupeCompany({ name }) {
            // Store current info.
            const currentContact = this.newContactForm;

            if (name) {
                const alreadyExists = this.contactOptions.find((contact) => {
                    return String(contact.name).toLowerCase() === String(name).toLowerCase();
                });

                if (alreadyExists) {
                    // Hack to bypass @input event from overwriting our data. @input triggeres after @option:created
                    setTimeout(() => {
                        this.newContactForm.firm = alreadyExists;
                    }, 100);
                } else {
                    setTimeout(() => {
                        this.newContactForm = {
                            ...currentContact,
                            name,
                        };
                    }, 100);
                }
            }
        },
        /**
         * Validate Phone Number
         */
        validatePhone(phone) {
            return ks.isPhoneNumberValid(phone);
        },
        /**
         * Validate Email
         */
        validateEmail(email) {
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            return email.match(validRegex);
        },
        resetValidationErrors() {
            this.validationErrors = defaultValidationErrors();
        },
        validateForm(contact, shouldValidatePerson, contactPerson) {
            // Reset previous errors
            this.resetValidationErrors();

            if (contact.phone?.length > 0 && !this.validatePhone(contact.phone)) {
                this.validationErrors.phone = true;
            }

            if (contact.mobile?.length > 0 && !this.validatePhone(contact.mobile)) {
                this.validationErrors.mobile = true;
            }

            if (contact.email?.length > 0 && !this.validateEmail(contact.email)) {
                this.validationErrors.email = true;
            }

            if (this.isPostalCodeRequired && !contact.postal_code) {
                this.validationErrors.postal_code = true;
            }

            if (this.isCityRequired && !contact.city) {
                this.validationErrors.city = true;
            }

            if (shouldValidatePerson) {
                if (contactPerson.phone?.length > 0 && !this.validatePhone(contactPerson.phone)) {
                    this.validationErrors.personPhone = true;
                }

                if (contactPerson.mobile?.length > 0 && !this.validatePhone(contactPerson.mobile)) {
                    this.validationErrors.personMobile = true;
                }

                if (contactPerson.email?.length > 0 && !this.validateEmail(contactPerson.email)) {
                    this.validationErrors.personEmail = true;
                }
            }

            if (Object.values(this.validationErrors).some(error => error)) {
                return false;
            }

            return true;
        },
        saveContact() {
            this.$emit('activate-spinner', true);
            // Validate email & phone if the values exists
            const validated = this.validateForm(this.newContactForm, this.shouldSendPerson, this.newContactPersonForm);

            if (!validated) {
                this.$emit('activate-spinner', false);

                return;
            }
            this.$emit('new-contact', {
                contact: this.newContactForm,
                contactPerson: this.shouldSendPerson ? this.newContactPersonForm : null,
            });
        },
        showContactPersonMode() {
            this.$emit('contact-person-mode', true);
        },
    },
};
</script>

<style lang="scss" scoped>
$disabledGrey: #ddd;
* {
    box-sizing: border-box;
}
.new-contact::v-deep {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 530px;
    overflow: auto;

    .new-contact-form {
        display: flex;
        flex-direction: column;
    }

    .required-text {
        display: block;
        margin-top: var(--spacing-1);
        margin-bottom: var(--spacing-1_5);
    }

    .center-items {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--spacing-1);
        .form-input {
            position: relative;
            z-index: 2; /** Hack to not make v-select input overflow next label-input */
            width: 180px;
            display: block;
            padding: 12px 8px;
            border-radius: 3px;
            box-sizing: border-box;
            border: 1px solid #D6D5D5;
            height: 30px;
            margin-left: 10px;
            font-size: inherit;
            appearance: textfield;
            -moz-appearance: textfield;
            &:disabled {
                background: $disabledGrey;
            }
        }
        .form-input::-webkit-inner-spin-button,
        .form-input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        .form-select {
            width: 180px;
            height: 30px;
            margin-left: 10px;

            .item-ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .vs__dropdown-toggle {
                height: 30px;
                justify-content: space-around;
            }

            .vs__selected-options {
                max-width: calc(100% - 40px);
            }

            .vs__selected {
                display: block;
                max-width: 100%;
                line-height: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .vs__search {
                font-size: 1em !important;
            }

            .vs__dropdown-menu {
                max-height: 230px;
                border-radius: 0;
            }
        }
    }

    .input-wrapper {
        display: flex;
        flex-flow: column;
    }

    .input-button-wrapper {
        display: flex;
        gap: var(--spacing-0_5);
        input {
            width: 144px;
        }
    }

    .checkbox-wrapper {
        display: flex;
        width: 180px;
        gap: 10px;
        height: 30px;

        .kk-checkbox {
            .label-content {
                margin-bottom: 0;
            }
        }
    }

    .kk-button.transparent-primary {
        padding: 0;
        font: var(--font-body-xs-medium);

        span.flex-fix{
            gap: var(--spacing-1);

            svg {
                width: 16px;
                height: 16px;
            }
        }
        &.mobile {
            font: var(--font-body-sm-medium);
        }
    }

    .error {
        color: red;
        margin-left: 10px;
        width: 180px;
        line-height: 1.4;
        display:flex;
        align-self: end;
    }
}

.mobile {
    .new-contact {
        .form {
            flex-direction: column;
        }
        .form::v-deep {
            max-height: unset;
            min-height: 400px;

            > div:first-of-type {
                margin-right: 0;
            }
        }
    }
}
.text-primary {
    color: var(--text-primary);
}
</style>
