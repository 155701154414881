<template>
    <kk-modal-v2
        v-if="desktop"
        class="kk-confirm-desktop"
        @cancel="$emit('cancel')"
    >
        <template #title>
            {{ title }}
        </template>
        <template #content>
            <div class="kk-confirm-desktop-text">
                <span v-if="text">
                    {{ text }}
                </span>
                <slot v-else name="text" />
            </div>
        </template>
        <template #footer>
            <kk-button
                v-if="cancelButtonText !== false"
                class="cancel-button"
                type="basic"
                @click="$emit('cancel')"
            >
                <template v-if="cancelButtonText">
                    {{ cancelButtonText }}
                </template>
                <template v-else>
                    {{ trans("shared.Avbryt") }}
                </template>
            </kk-button>

            <kk-button
                :type="confirmButtonType"
                :loading="loading"
                :disabled="loading"
                @click="$emit('confirm', confirmId)"
            >
                {{ confirmButtonText }}
            </kk-button>
        </template>
    </kk-modal-v2>
    <kk-card
        v-else
        :margin="0"
        class="kk-confirm-mobile"
    >
        <header>
            <h2>
                {{ title }}
            </h2>
        </header>
        <div class="kk-confirm-mobile-text">
            <span v-if="text">
                {{ text }}
            </span>
            <slot v-else name="text" />
        </div>
        <footer>
            <kk-button
                v-if="cancelButtonText !== false"
                big
                type="basic"
                full
                @click="$emit('cancel');"
            >
                <template v-if="cancelButtonText">
                    {{ cancelButtonText }}
                </template>
                <template v-else>
                    {{ trans('shared.Gå tilbake') }}
                </template>
            </kk-button>
            <kk-button
                big
                full
                :type="confirmButtonType"
                @click="$emit('confirm', confirmId);"
            >
                {{ confirmButtonText }}
            </kk-button>
        </footer>
    </kk-card>
</template>

<script>
import kkCard from '../kk-card/kk-card.vue';
import kkModalV2 from '../kk-modal-v2/kk-modal-v2.vue';
import KkButton from '../kk-button/kk-button.vue';
import trans from '../../filters/trans.js';

export default {
    name: 'kk-confirm',
    components: {
        KkButton,
        kkCard,
        kkModalV2,
    },
    props: {
        desktop: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
            required: true,
        },
        text: {
            type: String,
            default: '',
        },
        // In case of a confirmation, this is the id that'll be affected & returned from the `confirm` event
        confirmId: {
            type: Number,
            default: null,
        },
        confirmButtonText: {
            type: String,
            default: trans('shared.OK'),
        },
        confirmButtonType: {
            default: 'primary',
            type: String,
            validator: (value) => {
                return [
                    'basic',
                    'clean',
                    'danger',
                    'default',
                    'link',
                    'primary',
                    'success',
                    'warning',
                    'transparent',
                ].includes(value);
            },
        },
        cancelButtonText: {
            type: [String, Boolean],
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>

.kk-confirm-mobile::v-deep {
    header {
        h2 {
            margin-top: 0;
            margin-bottom: var(--spacing-2_5);
            font: var(--font-h4);
            color: var(--text-primary);
        }
    }

    .kk-confirm-mobile-text {
        color: var(--text-secondary);
        font: var(--font-body-md-regular);
    }

    footer {
        display: flex;
        gap: var(--spacing-2_5);
        padding: 0;
        margin: var(--spacing-3) 0 0 0;
        background-color: unset;

        .kk-button {
            width: 100%;
        }
    }
}

.kk-confirm-desktop::v-deep {
    font-family: var(--font-family-primary);

    .dialogbox {
        .content-section {
            .kk-confirm-desktop-text {
                margin: 0 0 var(--spacing-2_5) 0;
                color: var(--text-secondary);
                font: var(--font-body-sm-regular);
                white-space: pre-line;
            }
        }
    }
}

</style>
