<template>
    <span class="kk-truncate-collection">
        <span
            v-for="(item, index) in visibleItems"
            :key="index"
            class="kk-truncate-collection-items"
            :class="{ 'kk-truncate-collection-items-single': showAll }"
        >
            <slot name="element" :element="item">
                <span class="element-name">{{ getName(item) }}</span>
                <span v-if="visibleItems.length - 1 > index && !showAll">,&nbsp;</span>
            </slot>
        </span>

        <span v-if="collection.length > visibleItems.length">
            <kk-tooltip :text="notVisibleItemsTooltip">
                <slot name="more">
                    <span class="default-more-text">
                        + {{ collection.length - visibleItems.length }}
                        {{ moreText }}
                    </span>
                </slot>
            </kk-tooltip>
        </span>
    </span>
</template>

<script>
import trans from '../../filters/trans.js';
import kkTooltip from '../kk-tooltip/kk-tooltip.vue';
import _ from 'lodash';

export default {
    name: 'kk-truncate-collection',
    components: {
        kkTooltip,
    },
    props: {
        // It can be an array of strings or array of objects
        collection: {
            type: Array,
            required: true,
            default: () => [],
        },
        // If collection is array of objects and we need to specify which element's characters count
        // It should be a key of object
        elementName: {
            type: String,
            default: null,
            required: false,
        },
        // Number of items to show
        maxVisible: {
            type: Number,
            default: 2,
        },
        // Max number of characters to show if we want to make sure that all items fit in one line
        // Has to be set manually depending on the size of elements to display and available space
        // If it's set and the sum of charaters of items to show is higher, less items will be shown, but at least one
        maxCharacters: {
            type: Number,
            default: 30,
        },
        // Text to show when there are more items than maxVisible
        moreText: {
            type: String,
            default: trans('shared.Mer'),
        },
        // Prop allowing to not truncate collection and display all items
        showAll: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        maxItems() {
            return this.maxVisible < this.collection.length ? this.maxVisible : this.collection.length;
        },
        visibleItems() {
            // to show all items we just return collection
            if (this.showAll) {
                return this.collection;
            }
            // but if we have maxVisible limit we need to calculate which items to show
            // we need to start with ar least one item to show
            const itemsToShow = [this.collection[0]];
            let itemsToShowCharactersLength = this.getName(itemsToShow[0])?.length;

            // then we are adding next items to show until we reach maxVisible limit
            for (let i = 1; itemsToShow.length < this.maxItems; i++) {
                const item = this.collection[i];

                // additional check in case there is max charactes limit
                if (this.maxCharacters) {
                    if (itemsToShowCharactersLength + this.getName(item).length > this.maxCharacters) {
                        break;
                    }
                }

                itemsToShowCharactersLength += this.getName(item).length;
                itemsToShow.push(item);
            }

            return itemsToShow;
        },
        notVisibleItems() {
            return _.slice(this.collection, this.visibleItems.length);
        },
        notVisibleItemsTooltip() {
            return this.notVisibleItems?.map(i => this.getName(i))?.join(', ');
        },
    },
    methods: {
        getName(item) {
            return this.elementName ? item[this.elementName] : item;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables';
.default-more-text {
    color: var(--text-primary);
}
.kk-truncate-collection {
    display: block;
    width: inherit;
    line-height: initial;

    &-items {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &-single {
            display: block !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>
