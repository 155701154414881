import mixpanel from 'mixpanel-browser';

export function initMixpanel() {
    if (!import.meta.env.VITE_MIXPANEL_TOKEN) {
        return;
    }

    const token = import.meta.env.VITE_MIXPANEL_TOKEN;
    const config = {
        api_host: 'https://api-eu.mixpanel.com',
        persistence: 'localStorage',
        track_pageview: true,
    };

    mixpanel.init(token, config);
    mixpanel.reset();

    window.mixpanel = mixpanel;
}

export function logMixpanelEvent(eventName, data = {}) {
    if (!import.meta.env.VITE_MIXPANEL_TOKEN) {
        return;
    }

    window.mixpanel.track(eventName, data);
}

export const MIXPANEL_EVENTS = {
    KK_FIND_PERSON_OPENED_INTERACTION: 'KK_FIND_PERSON_OPENED_INTERACTION',
    KK_FIND_PERSON_DID_SEARCH: 'KK_FIND_PERSON_DID_SEARCH',
    KK_FIND_PERSON_DID_SELECT_PERSON: 'KK_FIND_PERSON_DID_SELECT_PERSON',
    KK_FIND_PERSON_DID_IMPORT: 'KK_FIND_PERSON_DID_IMPORT',

    SUPERADMIN_DID_SAVE_COMPANY: 'SUPERADMIN_DID_SAVE_COMPANY',
    SUPERADMIN_DID_SAVE_USER: 'SUPERADMIN_DID_SAVE_USER',

    DEVIATION_DID_SAVE_DEVIATION: 'DEVIATION_DID_SAVE_DEVIATION',
    DEVIATION_EXTERNAL_PROCESSING_DID_SHARE_LINK: 'DEVIATION_EXTERNAL_PROCESSING_DID_SHARE_LINK',
    DEVIATION_EXTERNAL_PROCESSING_SAVE: 'DEVIATION_EXTERNAL_PROCESSING_SAVE',
    DEVIATION_EXTERNAL_PROCESSING_TRANSFER_TEXT: 'DEVIATION_EXTERNAL_PROCESSING_TRANSFER_TEXT',

    EDIT_CONTACT_DID_SAVE_CONTACT: 'EDIT_CONTACT_DID_SAVE_CONTACT',
    EDIT_CONTACT_DID_SAVE_PERSON: 'EDIT_CONTACT_DID_SAVE_PERSON',
    EDIT_CONTACT_DID_SAVE_ADDRESS: 'EDIT_CONTACT_DID_SAVE_ADDRESS',

    EMPLOYEE_INFO_DID_SAVE_EMPLOYEE: 'EMPLOYEE_INFO_DID_SAVE_EMPLOYEE',

    OTP_DID_ENTER: 'OTP_DID_ENTER',
    OTP_DID_VERIFY: 'OTP_DID_VERIFY',

    ORDERS_CLICK_DELETE_ORDER: 'ORDERS_CLICK_DELETE_ORDER',
    ORDERS_DID_CREATE_ORDER: 'ORDERS_DID_CREATE_ORDER',
    ORDERS_DID_CREATE_FIRST_ORDER: 'ORDERS_DID_CREATE_FIRST_ORDER',
    ORDERS_DID_DELETE_ORDER: 'ORDERS_DID_DELETE_ORDER',
    ORDERS_DID_INVOICE_INVOICE: 'ORDERS_DID_INVOICE_INVOICE',
    ORDERS_DID_SET_TO_ACTIVE: 'ORDERS_DID_SET_TO_ACTIVE',
    ORDERS_ADD_PRODUCT: 'ORDERS_ADD_PRODUCT',
    ORDERS_ADD_ATTACHMENT: 'ORDERS_ADD_ATTACHMENT',
    ORDERS_TRANSFER_PRODUCT_TO_INVOICE: 'ORDERS_TRANSFER_PRODUCT_TO_INVOICE',
    ORDERS_TRANSFER_TIMETRACKING_TO_INVOICE: 'ORDERS_TRANSFER_TIMETRACKING_TO_INVOICE',
    ORDERS_ADD_ACCEPT: 'ORDERS_ADD_ACCEPT',
    ORDERS_GOT_PROJECT_ERROR: 'ORDERS_GOT_PROJECT_ERROR',
    ORDERS_GOT_CHANGE_ERROR: 'ORDERS_GOT_CHANGE_ERROR',

    PROJECT_TEMPLATES_CREATE: 'PROJECT_TEMPLATES_CREATE',
    PROJECT_TEMPLATES_DUPLICATE: 'PROJECT_TEMPLATES_DUPLICATE',
    PROJECT_TEMPLATES_EDIT: 'PROJECT_TEMPLATES_EDIT',
    PROJECT_TEMPLATES_SAVE: 'PROJECT_TEMPLATES_SAVE',
    PROJECT_TEMPLATES_ACTIVATE: 'PROJECT_TEMPLATES_ACTIVATE',
};

export const MIXPANEL_PROPS = {
    PLATFORM: 'PLATFORM',
    PLATFORM_DESKTOP: 'PLATFORM_DESKTOP',
    PLATFORM_MOBILE: 'PLATFORM_MOBILE',

    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DRAFT: 'DRAFT',
    COMPLETED: 'COMPLETED',
    REJECTED: 'REJECTED',
    DID_CREATE_OR_UPDATE: 'DID_CREATE_OR_UPDATE',

    DEVIATION_ADD_EXTERNAL_PROCESSOR: 'DEVIATION_WITH_NEW_EXTERNAL_PROCESSOR',
    DEVIATION_CHANGE_EXTERNAL_PROCESSOR: 'DEVIATION_CHANGE_EXTERNAL_PROCESSOR',
    DEVIATION_EXTERNAL_PROCESSOR_LINK_RESHARE: 'DEVIATION_EXTERNAL_PROCESSOR_LINK_RESHARE',
    DEVIATION_EXTERNAL_PROCESSING_TYPE: 'DEVIATION_EXTERNAL_PROCESSING_TYPE',

    ORDER_TYPE: 'ORDER_TYPE',
    ORDER_TYPE_SERVICE: 'ORDER_TYPE_SERVICE',
    ORDER_TYPE_PROJECT: 'ORDER_TYPE_PROJECT',
    ORDER_TYPE_CHANGE: 'ORDER_TYPE_CHANGE',
    ORDER_LOCATION: 'ORDER_LOCATION',
    ORDER_LOCATION_ORDER: 'ORDER_LOCATION_ORDER',
    ORDER_LOCATION_KANBAN: 'ORDER_LOCATION_KANBAN',
    ORDER_LOCATION_LIST: 'ORDER_LOCATION_LIST',
};

export const mixpanelMixin = {
    data: () => ({
        MIXPANEL_EVENTS,
        MIXPANEL_PROPS,
    }),
    methods: {
        logMixpanelEvent,
    },
};
