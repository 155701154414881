import { ks } from 'vue-components';
import axios from 'axios';

/**
 * @param  {string} url
 * @param  {Object} config={}
 *
 * @throw axios error
 * @return axios response.data
 */
export async function get(url, config = {}, catchError = true) {
    url = makeUrl(url);
    config = await makeConfig(config);

    if (!catchError) {
        return (await axios.get(url, config)).data;
    }

    try {
        return (await axios.get(url, config)).data;
    } catch (err) {
        logThrowApiError(err, url);
    }
}

/**
 * @param  {string} url
 * @param  {any} payload=null
 * @param  {Object} config={}
 *
 * @throw axios error
 * @return axios response.data
 */
export async function put(url, payload = null, config = {}, catchError = true, returnResponse = false) {
    url = makeUrl(url);
    config = await makeConfig(config);

    if (!catchError) {
        if (returnResponse) {
            return (await axios.put(url, payload, config));
        }

        return (await axios.put(url, payload, config)).data;
    }

    try {
        if (returnResponse) {
            return (await axios.put(url, payload, config));
        }

        return (await axios.put(url, payload, config)).data;
    } catch (err) {
        logThrowApiError(err, url, payload);
    }
}

/**
 * @param  {string} url
 * @param  {any} payload=null
 * @param  {Object} config={}
 *
 * @throw axios error
 * @return axios response.data
 */
export async function post(url, payload = null, config = {}, catchError = true) {
    url = makeUrl(url);
    config = await makeConfig(config);

    if (!catchError) {
        return (await axios.post(url, payload, config)).data;
    }

    try {
        return (await axios.post(url, payload, config)).data;
    } catch (err) {
        logThrowApiError(err, url, payload);
    }
}

/**
 * @param  {string} url
 * @param  {Object} config={}
 *
 * @throw axios error
 * @return axios response.data
 */
export async function del(url, config = {}, catchError = true) {
    url = makeUrl(url);
    config = await makeConfig(config);

    if (!catchError) {
        return (await axios.delete(url, config)).data;
    }

    try {
        return (await axios.delete(url, config)).data;
    } catch (err) {
        logThrowApiError(err, url);
    }
}

/**
 * @param {string} url
 */
function makeUrl(url) {
    if (!url || typeof url !== 'string') {
        throw new Error(`kk-api.makeUrl: Url has to be string. Was ${url}`);
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        // Prepend https://api.kvalitetskontroll.dev
        url = `${import.meta.env.VITE_KK_API_URL}${url}`;
    }

    return url;
}

/** State */
let kkApi = {
    access_token: null,
    access_token_expiration: null,
};

async function makeConfig(config) {
    const headers = config.headers ? config.headers : {};

    //
    // Make sure access token exists
    //
    if (kkApi.access_token === null) {
        let result;

        try {
            result = (await axios.get('/kkApi/token', { Accept: 'application/json' })).data;
        } catch (err) {
            clearTokensAndOpenLoginPopup();
            logThrowKsError(err);
        }

        // Check again before assigning, to prevent race conditions
        if (kkApi.access_token === null) {
            kkApi = result;
            console.log('Koblet til Kvalitetskontroll API.');
        }
    } else {
        //
        // If token is soon to expire, then refresh
        //
        if (new Date(kkApi.access_token_expiration) < nSecondsIntoTheFuture(10)) {
            let result;

            try {
                result = (await axios.get('/kkApi/token', { Accept: 'application/json' })).data;
            } catch (err) {
                clearTokensAndOpenLoginPopup();
                logThrowKsError(err);
            }

            // Check again before assigning, to prevent race conditions
            if (new Date(kkApi.access_token_expiration) < nSecondsIntoTheFuture(10)) {
                kkApi = result;
                console.log('Fornyet tilkobling med Kvalitetskontroll API.');
            }
        }
    }

    const defaultHeaders = {
        'Authorization': `Bearer ${kkApi.access_token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    return {
        ...config,
        headers: {
            ...defaultHeaders,
            ...headers,
        },
    };

    function nSecondsIntoTheFuture(nSeconds) {
        const now = new Date();
        now.setSeconds(now.getSeconds() + nSeconds);

        return now;
    }
}

export function clearTokensAndOpenLoginPopup() {
    kkApi = {
        access_token: null,
        access_token_expiration: null,
    };

    window.dispatchEvent(new CustomEvent('show-login-popup'));
}

/**
 *
 * @param {Object} err axios error
 * @param {String} url url that was attempted
 * @param {Object} [payload=null] optional payload
 */
export function logThrowApiError(err, url, payload = null, alert = false) {
    console.error(`Error when requesting url: ${url} with payload:`, payload);

    // If network error
    if (!err.response) {
        if (!window.navigator.onLine) {
            danger('Du er offline.');
        }
        throw err;
    }
    const status = err.response.status;
    const message = err.response.data.message ?? err.response.data.error ?? err.response.message;

    const fields = err.response.data.errors;
    let errors = [];

    if (fields) {
        for (let key in fields) {
            let val = fields[key][0];

            if (key.lastIndexOf('.') !== -1) {
                val = val.replace(key, key.substring(key.lastIndexOf('.') + 1));
            }

            errors.push(val);
        }
    } else {
        errors.push(message);
    }

    if (status === 401) {
        danger('API token er ikke gyldig. Vennligst logg inn på nytt.', status, alert);
        clearTokensAndOpenLoginPopup();
    } else if (status === 403) {
        danger(trans('shared.Du har ikke tilgang til denne siden') + '<br/>\n' + errors.join('<br/>\n'), status, alert);
    } else if (status === 404) {
        danger('Resursen ikke funnet', status, alert);
    } else if (status === 422) {
        danger(errors.join('<br/>\n'), status, alert);
    } else if (status === 500) {
        if (message !== null) {
            danger(message, status, alert);
        } else {
            danger('API har en ukjent feil. Vennligst kontakt support.', status, alert);
        }
    } else {
        if (message !== null) {
            danger(message, status, alert);
        } else {
            danger('En feil oppstod.', status, alert);
        }
    }
    throw err;
}

/**
 * @param err
 * @throws err
 */
function logThrowKsError(err) {
    // If network error
    if (!err.response) {
        if (!window.navigator.onLine) {
            danger('Du er offline.');
        }
        throw err;
    }
    const status = err.response.status;

    if (status === 503) {
        danger('Kvalitetssystemet er offline.', status);
    } else if (status === 504) {
        danger('API er offline.', status);
    } else if (status === 400) {
        danger('Innholdet i spørringen har en feil.', status);
    } else if (status === 401) {
        danger('API token er ikke gyldig. Vennligst forøsk å logge inn på nytt.', status);
        clearTokensAndOpenLoginPopup();
    } else if (status === 403) {
        danger('Du har ikke lov til å utføre denne handlingen.', status);
    } else if (status === 404) {
        danger('Resursen ikke funnet', status);
    } else if (status === 500) {
        danger('Kvalitetssystemet har en ukjent feil. Venligst kontakt support.', status);
    } else if (status === 502) {
        danger('API har en ukjent feil. Venligst kontakt support.', status);
    } else {
        danger('En ukjent feil oppstod. Vennligst kontakt support.', status);
    }
    throw err;
}

function danger(message, status = null, alert = false) {
    message = Lang.get(message);

    if (status) {
        message = `${message} (${status})`;
    }

    if (alert) {
        ks.alert(Lang.get('errors.Feil'), message);
    } else {
        ks.toast({
            message,
            duration: 10000,
            type: 'error',
            position: 'top',
        });
    }
}
