import kkButton from '../kk-button/kk-button.vue';
import kkIconButton from '../kk-icon-button//kk-icon-button.vue';
import svgIcon from '../svg-icon/svg-icon.vue';
import { trans } from '../../mixin/trans.js';
import ClickOutside from 'vue-click-outside';

// @vue/component
export default {
    name: 'kk-modal-v2',

    directives: {
        ClickOutside,
    },

    components: {
        kkButton,
        kkIconButton,
        svgIcon,
    },

    mixins: [
        trans,
    ],

    props: {
        size: {
            type: String,
            default: 'small',
            validator(value) {
                return ['small', 'medium', 'large', 'xlarge'].includes(value);
            },
        },
        type: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'separated'].includes(value);
            },
        },
        /**
         * Show close button
         * @default true
         */
        closeButton: {
            type: Boolean,
            default: true,
        },
        /**
         * Include footer
         * @default true
         */
        footer: {
            type: Boolean,
            default: true,
        },
        /**
         * Scroll lock the body. Does not work in iOS for now.
         * @default true
         */
        scrollLock: {
            type: Boolean,
            default: true,
        },
        okButtonLabel: {
            type: String,
            default: null,
        },
        cancelButtonLabel: {
            type: String,
            default: null,
        },
        okButtonDisabled: {
            type: Boolean,
            default: false,
        },
        showCancelButton: {
            type: Boolean,
            default: true,
        },
        showOkButton: {
            type: Boolean,
            default: true,
        },
        okButtonType: {
            type: String,
            default: 'primary',
            validator(value) {
                return ['primary', 'danger', 'success'].includes(value);
            },
        },
        // Displays loading state on 'ok' button
        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            showModal: false,
        };
    },

    computed: {
        isTypeSeparated() {
            return this.type === 'separated';
        },
    },

    created() {
        if (this.scrollLock) {
            this.setScrollLock(true);
        }

        // Show the modal after a small delay for a smooth transition
        setTimeout(() => {
            this.showModal = true;
        }, 100); // Adjust delay as necessary
    },

    beforeDestroy() {
        if (this.scrollLock) {
            this.setScrollLock(false);
        }
    },

    methods: {
        clickOutside(evt) {
            if (evt.target === this.$el) {
                // Allow for custom-event @click-outside, but default to emitting the @cancel event
                // if @click-outside doesn't have any listeners.
                if (this.$listeners['click-outside']) {
                    this.$emit('click-outside');
                } else {
                    this.$emit('cancel');
                }
            }
        },

        setScrollLock(on) {
            if (!on) {
                document.body.style.overflow = '';

                return;
            }

            document.body.style.overflow = 'hidden';
        },
    },
};
